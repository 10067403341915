import React from "react";
import useCanvas from "./useCanvas";

const _predraw = (context) => {};
const _postdraw = () => {};

const Canvas = (props) => {
  const {
    draw,
    predraw = _predraw,
    postdraw = _postdraw,
    ball_array,
    set_ball_array,
    setCanvasHeight,
    setCanvasWidth,
  } = props;
  const canvasRef = useCanvas(
    draw,
    predraw,
    postdraw,
    ball_array,
    set_ball_array,
    setCanvasWidth,
    setCanvasHeight
  );

  return <canvas ref={canvasRef} />;
};

export default Canvas;
