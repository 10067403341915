import { collisionDetect, update } from "./ball_logic/ball_logic";

export const draw = (ctx, frameCount, ball_array, width, height, MODE) => {
  //Welcome to draw()

  //console.log("Draw MODE : " + MODE);
  ball_array.forEach((ballObj, index) => {
    ctx.beginPath();
    ctx.fillStyle = ballObj.color;
    ctx.arc(ballObj.x, ballObj.y, ballObj.size, 0, 2 * Math.PI);
    ctx.fill();

    ball_array[index] = update(ballObj, width, height);
  });
};
