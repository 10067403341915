import React from "react";
import FancyBorder from "./FancyBorder";

function Dialog(props) {
  return (
    <FancyBorder color="blue">
      <h1 className="Dialog-title">{props.title}</h1>
      <p className="Dialog-message">{props.message}</p>
    </FancyBorder>
  );
}

function WelcomeDialog(props) {
  const test = props.test;
  const welcome = props.welcome || "Welcome";

  return <Dialog title={welcome} message={test} />;
}

export default WelcomeDialog;
