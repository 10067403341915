function random(min, max) {
  return Math.random() * (max - min) + min;
}

function randomRGB() {
  return `rgb(${random(0, 255)},${random(0, 255)},${random(0, 255)})`;
}

function getRandomNonZeroValue(min, max) {
  let value;
  do {
    value = random(min, max);
  } while (value === 0);
  return value;
}

export const Populate_Ball_Array = (num, w, h) => {
  let ball;
  let array = [];
  while (array.length < num) {
    ball = create_Ball(w, h);
    array.push(ball);
    //console.log("Ball " + index + " Added");
  }

  return array;
};

const create_Ball = (w, h) => {
  const size = random(24, 30);
  const ball = {
    x: random(0 + size, w - size),
    y: random(0 + size, h - size),
    velX: getRandomNonZeroValue(-1, 1),
    velY: getRandomNonZeroValue(-1, 1),
    color: randomRGB(),
    size: size,
  };

  return ball;
};

export const update = (ball, width, height) => {
  if (ball.x + ball.size >= width) {
    ball.velX = -Math.abs(ball.velX);
  }

  if (ball.x - ball.size <= 0) {
    ball.velX = Math.abs(ball.velX);
  }

  if (ball.y + ball.size >= height) {
    ball.velY = -Math.abs(ball.velY);
  }

  if (ball.y - ball.size <= 0) {
    ball.velY = Math.abs(ball.velY);
  }

  ball.x += ball.velX;
  ball.y += ball.velY;

  return ball;
};

export const add_ball = (w, h, array) => {
  const ball = create_Ball(w, h);
  array.push(ball);
  return array;
};

export const remove_ball = (array) => {
  array.shift();
  return array;
};

export const collisionDetect = (array) => {
  //console.log(array);
  let index = 0;
  array.forEach((ball) => {
    array.forEach((item) => {
      if (!(ball === item)) {
        const dx = ball.x - item.x;
        const dy = ball.y - item.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < ball.size + item.size) {
          if (Math.random(0, 1) < 0.5) {
            ball.color = item.color;
            array[index.color] = item.color;
          }
        }
      }
    });
    index++;
  });
  return array;
};
