import React, { useState } from "react";
import Canvas from "../components/Canvas";
import { draw } from "../components/Draw";
import { predraw, postdraw2 } from "../components/prePost_draw";
import WelcomeDialog from "../components/test-2/WelcomeDialog";
import { add_ball, remove_ball } from "../components/ball_logic/ball_logic";
import { Link } from "react-router-dom";

function Game() {
  //States
  const [ball_array, set_ball_array] = useState([]);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);

  function handleClick1() {
    //console.log("click");
    set_ball_array(add_ball(canvasWidth, canvasHeight, ball_array));
  }

  function handleClick2() {
    //console.log("click2");
    set_ball_array(remove_ball(ball_array));
  }

  // - - - HTML - - -

  return (
    <div className="App">
      <div className="flex_container">
        <div className="top">
          <button onClick={handleClick1}>Add Ball</button>
          <button onClick={handleClick2}>Remove Ball</button>
        </div>
        <WelcomeDialog welcome="Colours" test="Change on collison" />
        <div className="top">
          <p>Home</p>
          <Link to="/">Link</Link>
        </div>
      </div>

      <Canvas
        draw={draw}
        predraw={predraw}
        postdraw={postdraw2}
        ball_array={ball_array}
        set_ball_array={set_ball_array}
        setCanvasWidth={setCanvasWidth}
        setCanvasHeight={setCanvasHeight}
      />
      <div>
        <p>&lt; &#x1F603; &gt;</p>
      </div>
    </div>
  );
}

export default Game;
