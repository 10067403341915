import { useRef, useEffect } from "react";
import { Populate_Ball_Array } from "./ball_logic/ball_logic";

const useCanvas = (
  draw,
  predraw,
  postdraw,
  ball_array,
  set_ball_array,
  setCanvasWidth,
  setCanvasHeight
) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const { width, height } = canvas.getBoundingClientRect();
    setCanvasWidth(width);
    setCanvasHeight(height);
    let frameCount = 0;
    let animationFrameId;

    if (ball_array.length < 20) {
      set_ball_array(Populate_Ball_Array(20, width, height));
    }

    const render = () => {
      const { width, height } = canvas.getBoundingClientRect();
      setCanvasWidth(width);
      setCanvasHeight(height);
      predraw(context, canvas);
      draw(context, frameCount, ball_array, width, height);
      if (ball_array.length > 1) {
        set_ball_array(
          postdraw(context, frameCount, ball_array, set_ball_array)
        );
      }
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [ball_array[0]]);

  return canvasRef;
};

export default useCanvas;
