import { collisionDetect } from "./ball_logic/ball_logic";

export const postdraw = (ctx, index, ball_array) => {
  index++;
  ctx.restore();

  return ball_array;
};

export const postdraw2 = (ctx, index, ball_array, set_ball_array) => {
  index++;
  ctx.restore();
  let array = [];
  array = collisionDetect(ball_array);

  return array;
};

export const predraw = (context, canvas) => {
  context.save();
  resizeCanvas(canvas);
  const { width, height } = context.canvas;
  context.clearRect(0, 0, width, height);
};

function resizeCanvas(canvas) {
  const { width, height } = canvas.getBoundingClientRect();

  if (canvas.width !== width || canvas.height !== height) {
    const { devicePixelRatio: ratio = 1 } = window;
    const context = canvas.getContext("2d");
    canvas.width = width * ratio;
    canvas.height = height * ratio;
    context.scale(ratio, ratio);
    return true;
  }

  return false;
}
